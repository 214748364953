<template>
  <div class="certificates">
    <panes>
      <template v-slot:left>
        <div class="certificate-list">
          <h1>
            SSL Certificate
            <base-button
              class="right"
              type="THEMED"
              iconType="PLUS"
              title="Create new Certificate"
              v-on:click="createOne"
            />
          </h1>
          <TableFilter
            :source="certificates"
            :fields="[{ key: 'name', label: 'Name', type: 'RegExp' }]"
            @filtered="
              (filtered) => {
                certificatesDisplay = filtered;
              }
            "
          />
          <Table
            v-if="ajaxCompleted"
            v-on:rowClick="handleRowClick"
            v-on:reloadData="getcerts"
            :tabledata="certificatesDisplay"
          />
        </div>
      </template>
      <template v-slot:right>
        <div class="certificate-view">
          <CreateEdit
            v-if="['certificateEdit'].indexOf($route.name) > -1 && typeof selected === 'string'"
            :certificate-id="selected"
            v-on:saved="handleSaved"
            v-on:deleted="handleDeleted"
          />
          <CreateEdit
            v-else-if="create"
            v-on:cancel="create = false"
            :certificate-id="-1"
            v-on:saved="handleSaved"
            v-on:deleted="handleDeleted"
          />
          <Deployments
            v-else-if="['certificateDeploy'].indexOf($route.name) == -1"
            :certificates="certificates"
            @reloadCertificates="getcerts"
          />
          <Deploy
            v-if="
              !create &&
              ['certificateDeploy'].indexOf($route.name) > -1 &&
              typeof selected === 'string'
            "
            :certificate-id="selected"
            v-on:saved="handleSaved"
          />
        </div>
      </template>
    </panes>
  </div>
</template>
<script>
import Utils from '@/utils';
import Panes from '../components/Panes/Panes.vue';
import Table from '../components/Certificate/Table.vue';
import CreateEdit from '../components/Certificate/CreateEdit.vue';
import Deploy from '../components/Certificate/Deploy.vue';
import Deployments from '../components/Certificate/Deployments.vue';
import BaseButton from '../components/BaseButton/BaseButton.vue';
import TableFilter from '../components/Table/Filter.vue';

export default {
  name: 'Alert',
  components: {
    Panes,
    Table,
    CreateEdit,
    Deploy,
    Deployments,
    BaseButton,
    TableFilter,
  },
  data() {
    return {
      ajaxCompleted: false,
      selected: null,
      create: false,
      certificates: [],
      certificatesDisplay: [],
    };
  },
  watch: {
    $route(val) {
      if (typeof val.params.certificate !== 'undefined') {
        this.selected = val.params.certificate;
      }
    },
  },
  methods: {
    handleRowClick(id) {
      this.create = false;
      this.snaps = false;
      this.selected = id;
      if (typeof id === 'string') {
        this.$router.replace(`/certificate/${id}/edit`);
      }
    },
    handleSaved() {
      this.$root.$emit('reloadTable');
    },
    handleDeleted() {
      this.handleSaved();
      this.selected = null;
    },
    createOne() {
      this.create = true;
      this.$router.replace('/certificate/create');
    },
    mapToTableData() {
      this.certificates.forEach((certificate, index) => {
        const validTo = this.$date(certificate.validTo);
        const validFormat = 'YYYY-MM-DD HH:mm[Z]';
        this.certificates[index] = {
          ...certificate,
          validFrom: this.$date(certificate.validFrom).utc().format(validFormat),
          validTo: this.$date(certificate.validTo).utc().format(validFormat),
          lifetime: validTo.diff(this.$date(), 'day'),
          devices: certificate.deployments.length,
          id: certificate._id,
        };
      });
    },
    async getcerts() {
      this.ajaxCompleted = false;
      const response = await Utils.fetch('/api/v1/certificates', {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.ajaxCompleted = true;
        this.certificates = response.certificates;
        this.mapToTableData();
      }
    },
  },
  async mounted() {
    await this.getcerts();
    if (this.$route.meta.create) {
      this.create = true;
      this.selected = null;
    }
    if (typeof this.$route.params.certificate !== 'undefined') {
      this.selected = this.$route.params.certificate;
    }
  },
};
</script>

<style lang="scss" scoped>
.certificates {
  height: 100%;
}

.certificate-list {
  height: 100%;
  overflow-y: auto;
}

.certificate-view {
  height: 100%;
  overflow-x: auto;
}
</style>
