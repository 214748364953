<template>
  <div v-if="ajaxCompleted">
    <h1 class="title" v-if="!create">Edit Certificate "{{ certificate.name }}"</h1>
    <h1 class="title" v-else>Create new Certificate</h1>
    <grid>
      <grid-col size="6">
        <card title="Certificate">
          <grid>
            <grid-col size="6">
              <Property title="Keyfile">
                <div v-if="certificate && certificate.keyfile">
                  {{ certificate.keyfile.length }} bytes
                </div>
                <vue-dropzone
                  ref="keyfileDropzone"
                  id="keyfileDropzone"
                  :options="dropZoneOptions('keyfile')"
                  :useCustomSlot="true"
                  v-if="showDropzones"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">Drop key here</h3>
                    <div class="subtitle">...or click to select a file</div>
                  </div>
                </vue-dropzone>
              </Property>
            </grid-col>
            <grid-col size="6">
              <Property title="Certificate">
                <div v-if="certificate && certificate.certfile">
                  {{ certificate.certfile.length }} bytes
                </div>
                <vue-dropzone
                  ref="keyfileDropzone"
                  id="keyfileDropzone"
                  :options="dropZoneOptions('certfile')"
                  :useCustomSlot="true"
                  v-if="showDropzones"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">Drop cert here</h3>
                    <div class="subtitle">...or click to select a file</div>
                  </div>
                </vue-dropzone>
              </Property>
            </grid-col>
          </grid>

          <div style="height: 30px">
            <base-button
              v-if="checkedPEM && checkedKEY"
              v-on:click="onSave"
              isSmall
              class="left"
              iconType="CHECK"
              title="Save certificate"
            />
            <base-button
              v-if="!create"
              type="THEMED_DELETE"
              iconType="TRASH"
              v-on:click="deleteCertificate"
              title="Delete"
              class="right"
            />
            <base-button
              v-on:click="onCancel"
              iconType="CANCEL"
              class="right"
              type="THEMED"
              title="Cancel"
            />
          </div>
          <br />
        </card>
        <card title="Deployments" v-if="!create">
          <p>There are currently {{ certificate.deployments.length }} deployments</p>
          <p v-if="failedDeployments.length">
            Of these, {{ failedDeployments.length }} deployments have failed
            <ul>
              <li v-for="d in failedDeployments" :key="d.uuid" class="error">{{d.error}}</li>
            </ul>
          </p>
          <base-button v-on:click="editDeployments" type="THEMED" title="Manage" />
        </card>
      </grid-col>
      <grid-col size="6">
        <card title="Certificate details" v-if="certificate.name">
          <Property title="Name">
            <div v-if="certificate.name">
              {{ certificate.name }}
            </div>
          </Property>
          <Property title="Serial">
            <div v-if="certificate.serial">
              {{ certificate.serial }}
            </div>
          </Property>
          <Property title="Issuer">
            <div v-if="certificate.issuer">
              {{ certificate.issuer }}
            </div>
            <div v-else>Available after upload</div>
          </Property>
          <Property title="Domains">
            <div v-if="certificate.domains">
              <span v-for="domain in certificate.domains" :key="domain">
                {{ domain }}
              </span>
            </div>
          </Property>
          <Property title="Valid From">
            <div v-if="certificate.validFrom">
              {{ certificate.validFrom }}
            </div>
          </Property>
          <Property title="Valid To">
            <div v-if="certificate.validTo">
              {{ certificate.validTo }}
            </div>
          </Property>
          <br />
          <base-button v-on:click="copyPem('bundle')" type="THEMED" title="Copy PEM bundle" />
          <base-button v-on:click="copyPem('key')" type="THEMED" title="Copy KEY" />
          <base-button v-on:click="copyPem('cert')" type="THEMED" title="Copy CERT" />
        </card>
        <card title="Certificate check" v-if="false && checkedPEM">
          <Property title="Name">
            {{ checkedPEM.name }}
          </Property>
          <Property title="Serial">
            <div>
              {{ checkedPEM.serial }}
            </div>
          </Property>
          <Property title="Issuer">
            <div>
              {{ checkedPEM.issuer }}
            </div>
          </Property>
          <Property title="Domains">
            <div>
              <span v-for="domain in checkedPEM.domains" :key="domain">
                {{ domain }}
              </span>
            </div>
          </Property>
          <Property title="Valid From">
            <div>
              {{ checkedPEM.validFrom }}
            </div>
          </Property>
          <Property title="Valid To">
            <div>
              {{ checkedPEM.validTo }}
            </div>
          </Property>
        </card>
        <card title="Certificate check" v-else-if="checkedPEMError">
          <b>Error in certificate.</b><br />
          <div v-if="checkedPEMError" class="darkred">
            <p>{{ checkedPEMError }}</p>
          </div>
          You can only upload certificates, not bundles
        </card>
      </grid-col>
    </grid>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import Utils from '@/utils';
import Property from '@/components/Util/Property.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';
import BaseComponent from '../Base.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'CreateEditcertificate',
  extends: BaseComponent,
  components: {
    Property,
    Card,
    Grid,
    GridCol,
    BaseButton,
    vueDropzone: vue2Dropzone,
  },

  props: ['certificateId'],
  watch: {
    certificateId(newVal) {
      this.showDropzones = false;
      this.checkedPEMError = false;
      this.getcertificate(newVal).then(() => {
        this.showDropzones = true;
      });
    },
  },
  data() {
    return {
      showDropzones: false,
      ajaxCompleted: false,
      create: true,
      certificate: null,
      checkedKEY: false,
      checkedPEM: null,
      checkedPEMError: false,
      canSave: false,
    };
  },
  computed: {
    failedDeployments() {
      return this.certificate.deployments.filter((d) => d.finished && !d.success);
    },
  },
  methods: {
    copyToClipboard(text) {
      const dummy = document.createElement('textarea');
      // dummy.style.visibility = 'hidden';
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
    },
    copyPem(which) {
      switch (which) {
        case 'key':
          this.copyToClipboard(this.certificate.keyfile);
          break;
        case 'cert':
          this.copyToClipboard(this.certificate.certfile);
          break;
        default:
          this.copyToClipboard([this.certificate.keyfile, this.certificate.certfile].join(''));
          break;
      }
      this.$noty.success(`${which} copied to clipboard!`);
    },
    template() {
      return `<div class="dz-preview dz-file-preview">
                <div class="dz-image">
                    <div data-dz-thumbnail-bg></div>
                </div>
                <div class="dz-details">
                    <div class="dz-size"><span data-dz-size></span></div>
                    <div class="dz-filename"><span data-dz-name></span></div>
                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                <div class="dz-error-mark"><i class="fa fa-close"></i></div>
            </div>
        `;
    },
    dropZoneOptions(which) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      return {
        url: `/api/v1/certificates/check/${which}`,
        autoProcessQueue: false,
        previewTemplate: self.template(),
        accept(file, done) {
          // console.log({ accept: file });
          const reader = new FileReader();
          reader.addEventListener('loadend', (event) => {
            const content = event.target.result;
            if (content.match(/-----BEGIN ((RSA )?PRIVATE KEY|CERTIFICATE)/)) {
              // console.log({
              //   which,
              //   content,
              //   // what: content.match(/-----PRIVATE KEY/),
              // });
              if (content.match(/-----BEGIN CERTIFICATE/)) {
                self.checkedPEM = null;
                self.validateCertificateFile(content);
              } else {
                self.certificate.keyfile = content;
                self.checkedKEY = true;
                self.canSave = true;
              }
              done();
            } else {
              done('Error! Files of this type are not accepted');
            }
          });
          reader.readAsText(file);
          // if (file.type !== 'application/x-x509-ca-cert') {
          //   done('Error! Files of this type are not accepted');
          // } else {
          //   done();
          // }
        },
        init() {
          this.on('addedfile', () => {
            if (this.files.length > 1 && this.files[1] != null) {
              this.removeFile(this.files[0]);
            }
            // console.log(this.files);
          });
        },
      };
    },
    async validateCertificateFile(certfile) {
      const response = await Utils.fetch(
        `/api/v1/certificates/${this.certificate._id}/check/certificate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            certfile,
          }),
        },
        this,
      ).then((res) => res.json());

      this.checkedPEMError = false;
      if (response.pem) {
        this.checkedPEM = response.pem;
        if (this.create) {
          this.certificate = { ...this.checkedPEM, ...this.certificate };
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const key of ['domains', 'issuer', 'name', 'serial', 'validFrom', 'validTo']) {
            this.certificate[key] = this.checkedPEM[key];
          }
        }
        this.certificate.certfile = certfile;

        if (!this.create) {
          if (this.certificate.name !== this.checkedPEM.name) {
            this.checkedPEM = null;
            this.checkedPEMError = 'Names do not match';
          }
        }
      } else {
        this.checkedPEMError = ' ';
      }
    },
    async validateKeyFile(keyfile) {
      // console.log({ keyfile });
      const response = await Utils.fetch(
        `/api/v1/certificates/${this.certificate._id}/check/keyfile`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            keyfile,
          }),
        },
        this,
      ).then((res) => res.json());

      if (response.pem) {
        this.certificate.keyfile = keyfile;
        // if (!this.create) {
        //   if (this.certificate.name === this.checkedPEM.name) {
        //     this.canSave = true;
        //   } else {
        //     this.canSave = false;
        //   }
        // } else {
        //   this.canSave = true;
        // }
      } else {
        this.checkedPEMError = true;
        this.canSave = false;
      }
    },
    onCancel() {
      this.$router.push('/certificate');
      this.$emit('cancel');
      this.selected = null;
    },
    editDeployments() {
      this.$router.push(`/certificate/${this.certificateId}/deploy`);
    },
    async onSave() {
      if (typeof this.certificate && this.certificate._id) {
        await this.handleOnEdit();
      } else {
        await this.handleOnSave();
      }
    },
    async deleteCertificate() {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Do you really want to delete this certificate?')) {
        const response = await Utils.fetch(
          `/api/v1/certificates/${this.certificateId}`,
          {
            method: 'DELETE',
          },
          this,
        ).then((res) => res.json());

        if (response.success) {
          this.$noty.success(`Successfully deleted the certificate '${this.certificate.name}'`);
          this.$emit('deleted');
          this.$router.replace('/certificate');
        } else {
          this.$noty.warning(response.message);
        }
      }
    },
    async handleOnEdit() {
      const response = await Utils.fetch(
        `/api/v1/certificates/${this.certificate._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            certificate: this.certificate,
          }),
        },
        this,
      ).then((res) => res.json());

      if (response.success) {
        this.$noty.success('Saved certificate');
        this.$emit('saved');
        await this.$router.push('/certificate');
      } else {
        this.$noty.warning(response.message);
      }
    },
    async handleOnSave() {
      // console.log(this.certificate);
      const response = await Utils.post(
        '/api/v1/certificates',
        {
          ...this.certificate,
        },
        this,
      );

      if (response.success) {
        this.$emit('saved');
        this.$noty.success('Saved certificate');
        this.onCancel();
      } else {
        this.$noty.warning(response.message);
      }
    },
    async getcertificate(id) {
      if (id === -1) {
        const response = await Utils.fetch(
          '/api/v1/certificates/helper/empty',
          {},
          this,
        ).then((res) => res.json());
        if (response.success) {
          this.ajaxCompleted = true;
          this.certificate = response.certificate;
          this.create = true;
        }
      } else {
        const response = await Utils.fetch(`/api/v1/certificates/${id}`, {}, this).then((res) =>
          res.json(),
        );
        if (response.success) {
          this.ajaxCompleted = true;
          this.certificate = response.certificate;
          this.create = false;
        }
      }
    },
    async setcertificateName(e) {
      if (this.create) {
        const name = e.target.options[e.target.options.selectedIndex].text;
        this.certificate.name = e.target.value ? name : null;
      }
    },
  },
  mounted() {
    this.getcertificate(this.certificateId).then(() => {
      this.showDropzones = true;
    });
  },
};
</script>
<style>
.vue-dropzone {
  border: 1px solid #f6f6f627;
  margin-top: 10px;
}

.vue-dropzone:hover {
  background-color: #f6f6f627;
}

.vue-dropzone:hover .dz-preview {
  background-color: var(--accent-color);
}

.dropzone .dz-preview:hover {
  background-color: var(--accent-color);
}

.vue-dropzone .dropzone-custom-content {
  padding: 10px;
}

.dropzone .dz-message {
  margin: 0;
}

.dropzone .dz-preview .dz-details {
  padding: 10px;
}

.dropzone-custom-content {
  padding: 10px 0;
}

.dropzone-custom-title {
  color: var(--button-color);
  margin: 0;
}

.subtitle {
  color: var(--secondary-font-color);
}

.dz-image,
.dz-success-mark,
.dz-error-mark {
  display: none;
}
</style>
