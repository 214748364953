<template>
  <div>
    <div class="filter-wrapper">
      <card>
        <h4>
          Filter
          <span v-if="matched" class="right">Matched: {{ matched }}</span>
        </h4>
        <input type="text" :placeholder="placeholder" class="input-text" v-model="filter.text" />
        <template v-if="hasSelect()">
          <div class="right" v-for="select in selects()" :key="select.key">
            {{ select.label }}:
            <select class="input-select" style="width: auto" v-model="filter[select.key]">
              <option v-for="(text, val) in select.options" :key="val" :value="val">
                {{ text }}
              </option>
            </select>
          </div>
        </template>
        <div><a class="link" @click="clearFilter()">clear filter</a></div>
      </card>
    </div>
  </div>
</template>
<script>
import { getProperty } from 'dot-prop';
import Card from '../Card/Card.vue';

export default {
  components: { Card },
  name: 'TableFilter',
  props: ['source', 'fields'],
  data() {
    return {
      placeholder: 'Filter on: ',
      filter: { text: '' },
      matched: 0,
      emptyFilter: {},
    };
  },
  watch: {
    filter: {
      handler() {
        this.filterRows();
      },
      deep: true,
    },
    source() {
      this.filterRows();
    },
  },
  methods: {
    async filterRows() {
      let filtered = [];
      // eslint-disable-next-line arrow-body-style
      filtered = this.source.filter((r) => {
        for (let index = 0; index < this.fields.length; index += 1) {
          const field = this.fields[index];
          const prop = getProperty(r, field.key, '');
          // match text field
          if (this.filter.text) {
            if (field.type === 'RegExp' && typeof prop === 'string') {
              const words = this.filter.text.split(' ');
              const rv = words.every((w) => prop.match(RegExp(w, 'i')));
              if (!rv) {
                return false;
              }
            }
          }
          // match select on single value field
          if (this.filter[field.key]) {
            if (field.type === 'select' && typeof prop === 'string') {
              const rv = this.filter[field.key] === prop;
              if (!rv) {
                return false;
              }
            }
          }
        }
        // if nothing matched this far, it didn't match - duh!
        return true;
      });
      this.$emit('filtered', filtered);
      this.matched = filtered.length;
      this.persistFilter();
    },
    persistKey() {
      return `filter-${this.$route.path.substring(1)}`; // remove slash
    },
    // save filter in localStorage
    persistFilter() {
      localStorage.setItem(this.persistKey(), JSON.stringify(this.filter));
    },
    loadFilter() {
      const filter = localStorage.getItem(this.persistKey());
      if (filter) {
        this.filter = JSON.parse(filter);
      }
    },
    // clear the filter
    clearFilter() {
      this.filter = JSON.parse(JSON.stringify(this.emptyFilter));
      this.filterRows();
    },
    // get all selects
    selects() {
      return this.fields.filter((f) => f.type === 'select');
    },
    // is there at least one select?
    hasSelect() {
      return this.selects().length;
    },
  },
  mounted() {
    this.placeholder += this.fields
      .filter((f) => f.type === 'RegExp')
      .map((f) => f.label)
      .join(', ');
    this.selects().forEach((s) => {
      this.filter[s.key] = '';
    });
    this.emptyFilter = JSON.parse(JSON.stringify(this.filter));
    this.loadFilter();
    this.filterRows();
  },
};
</script>
