<template>
  <div v-if="certificates">
    <h1>
      Deployments overview <a class="link" @click="reload"><i class="uil uil-sync"></i></a>
    </h1>
    <grid>
      <grid-col size="10">
        <card v-if="deploying">
          <div v-if="deploying" class="loader">
            <p>Waiting for deployments to finish</p>
            <img :src="loadingImage" />
          </div>
        </card>
        <card v-else-if="certificates.length">
          <div v-if="!withErrors.length && !needsDeployments.length">
            <h2>Everything seems to be in working order.</h2>
            <p>Notifications about updated certificates will be sent to Slack</p>
          </div>
          <div v-if="withErrors.length">
            <h3>Certificates with errors</h3>
            <div v-for="certificate in withErrors" :key="'error-' + certificate._id">
              <!-- certificates with errors -->
              <h5>
                {{ certificate.name }}
                <router-link :to="`/certificate/${certificate._id}/edit`"
                  ><i class="uil uil-external-link-alt"></i
                ></router-link>
              </h5>
              <div
                v-for="deploy in certificate.deployments"
                :key="['error', certificate._id, deploy.device].join(':')"
              >
                <div>
                  <span v-if="deploy.started && deploy.finished">
                    <i v-if="!deploy.error" class="uil uil-check-circle success"></i>
                    <i v-else class="uil uil-exclamation-triangle error"></i>
                  </span>
                  <span v-else>
                    <i class="uil uil-question-circle"></i>
                  </span>
                  <b>{{ deploy.name }}</b>
                </div>
                <div v-if="deploy.started || deploy.finished" style="padding-left: 20px">
                  <span v-if="deploy.started">{{ datefmt(deploy.started) }}</span>
                  -
                  <span v-if="deploy.finished">{{ datefmt(deploy.finished) }}</span>
                  <span v-if="deploy.started && deploy.finished">
                    : {{ elapsed(deploy.started, deploy.finished) }}
                  </span>
                  <div v-if="deploy.error">
                    <b class="error">{{ deploy.error }}</b>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
          <div v-if="needsDeployments.length">
            <h3>Certificates needing deployment</h3>
            <div v-for="certificate in needsDeployments" :key="'deploy-' + certificate._id">
              <!-- certificates that need deployment -->
              <h5>
                {{ certificate.name }}
                <router-link :to="`/certificate/${certificate._id}/deploy`"
                  ><i class="uil uil-external-link-alt"></i
                ></router-link>
              </h5>
              <div
                v-for="deployment in certificate.deployments"
                :key="['deploy', certificate._id, deployment.device].join(':')"
              >
                <label class="deployCheckbox">
                  <div class="checkbox">
                    <input
                      type="checkbox"
                      :value="deployment.device"
                      checked="checked"
                      @change="selectOne($event, certificate, deployment)"
                    />
                  </div>
                  <div class="label">{{ deployment.name }}</div>
                </label>
                <br />
              </div>
            </div>
            <a class="link" @click="deployAll">Deploy certificates to all checked devices</a>
          </div>
        </card>
      </grid-col>
      <!-- <p><a class="link" @click="testdeploy">test</a></p> -->
    </grid>
  </div>
</template>

<script>
import Utils from '@/utils';
import BaseComponent from '../Base.vue';
import Card from '../Card/Card.vue';
import Grid from '../Grid/Grid.vue';
import GridCol from '../Grid/GridCol.vue';

export default {
  name: 'CreateEditcertificate',
  extends: BaseComponent,
  components: {
    Card,
    Grid,
    GridCol,
  },
  data() {
    return {
      toDeploy: {},
      deploying: false,
    };
  },
  props: ['certificates'],
  watch: {},
  computed: {
    all() {
      return this.certificates.filter((cert) => cert.name === 'yoyo.wherever.network');
    },
    withErrors() {
      return this.certificates
        .filter(
          (cert) =>
            cert.deployments.length && cert.deployments.filter((dep) => !dep.success).length,
        )
        .map((cert) =>
          // return only deployments with error
          ({ ...cert, deployments: cert.deployments.filter((dep) => !dep.success) }),
        );
    },
    needsDeployments() {
      return this.certificates
        .filter(
          (cert) =>
            cert.deployments.length &&
            cert.deployments.filter((dep) => dep.currentlyDeployedSerial !== cert.serial).length,
        )
        .map((cert) => ({
          ...cert,
          deployments: cert.deployments.map((deployment) => {
            this.toDeploy[deployment.device] = cert;
            return { ...deployment, selected: true };
          }),
        }));
    },
  },
  methods: {
    onCancel() {
      this.$router.push('/certificate');
    },
    datefmt(str) {
      return this.$date(str).format('LLL');
    },
    elapsed(st, fi) {
      return Utils.durationShort(this.$date(fi).subtract(this.$date(st)), {
        units: ['m', 's'],
        round: true,
      });
    },
    selectAll(event) {
      // eslint-disable-next-line no-console
      console.log('all checkboxes toggle', event.target.checked);
    },
    selectOne(event, certificate, deployment) {
      if (event.target.checked) {
        this.toDeploy[deployment.device] = certificate;
      } else {
        delete this.toDeploy[deployment.device];
      }
    },
    async deploy(certificate, device) {
      try {
        await this.$utils.http().post(`/api/v1/certificates/${certificate._id}/execute-deploy`, {
          devices: [device],
        });
        this.$noty.success(`Deployed certificate ${certificate.name} to ${device}`);
      } catch (e) {
        this.$noty.warning(`could not deploy certificate: ${e}`);
      }
    },
    async deployAll() {
      const devices = Object.keys(this.toDeploy);
      const promises = devices.map((deviceId) =>
        this.$utils
          .http()
          .post(`/api/v1/certificates/${this.toDeploy[deviceId]._id}/execute-deploy`, {
            devices: [deviceId],
          }),
      );
      await Promise.all(promises);
      this.$noty.success(`Sent ${devices.length} deploy commands`);
      this.deploying = true;
      setTimeout(() => {
        this.deploying = false;
        this.reload();
      }, devices.length * 1000); // wait 1s for each deployment
    },
    async reload() {
      this.$emit('reloadCertificates');
    },
    async testdeploy() {
      this.deploying = true;
      this.$noty.success('Sent 1234 deploy commands');
      setTimeout(() => {
        this.deploying = false;
        this.reload();
      }, 10000);
    },
  },
  async mounted() {
    // this.getcerts();
    // console.log(this.certificates);
  },
};
</script>
<style scoped>
.deployCheckbox div.checkbox {
  float: left;
}
.deployCheckbox div.label {
  margin-left: 20px;
  top: 1px;
  position: relative;
}
</style>
<style lang="scss" scoped>
.loader {
  text-align: center;
  padding: 20px 0;

  img {
    height: 100px;
  }
}
</style>
