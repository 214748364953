var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"certificates"},[_c('panes',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',{staticClass:"certificate-list"},[_c('h1',[_vm._v(" SSL Certificate "),_c('base-button',{staticClass:"right",attrs:{"type":"THEMED","iconType":"PLUS","title":"Create new Certificate"},on:{"click":_vm.createOne}})],1),_c('TableFilter',{attrs:{"source":_vm.certificates,"fields":[{ key: 'name', label: 'Name', type: 'RegExp' }]},on:{"filtered":(filtered) => {
              _vm.certificatesDisplay = filtered;
            }}}),(_vm.ajaxCompleted)?_c('Table',{attrs:{"tabledata":_vm.certificatesDisplay},on:{"rowClick":_vm.handleRowClick,"reloadData":_vm.getcerts}}):_vm._e()],1)]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"certificate-view"},[(['certificateEdit'].indexOf(_vm.$route.name) > -1 && typeof _vm.selected === 'string')?_c('CreateEdit',{attrs:{"certificate-id":_vm.selected},on:{"saved":_vm.handleSaved,"deleted":_vm.handleDeleted}}):(_vm.create)?_c('CreateEdit',{attrs:{"certificate-id":-1},on:{"cancel":function($event){_vm.create = false},"saved":_vm.handleSaved,"deleted":_vm.handleDeleted}}):(['certificateDeploy'].indexOf(_vm.$route.name) == -1)?_c('Deployments',{attrs:{"certificates":_vm.certificates},on:{"reloadCertificates":_vm.getcerts}}):_vm._e(),(
            !_vm.create &&
            ['certificateDeploy'].indexOf(_vm.$route.name) > -1 &&
            typeof _vm.selected === 'string'
          )?_c('Deploy',{attrs:{"certificate-id":_vm.selected},on:{"saved":_vm.handleSaved}}):_vm._e()],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }